import "./manuals.scss";

import "@mod-publisher/js/richcontent/all";

import "./header/";
import "./main/main.scss";
import "./components/sidenav/";
import "./footer/footer.scss";
import "./pages/search";
import "./shared/forms";
import "./components/banner/banner.scss";
import * as dompack from "dompack";


dompack.onDomReady(() =>{
	if(document.documentElement.classList.contains('has_banner')){
		var banner = document.getElementById('banner');
		document.addEventListener("scroll", function(){
		  if(window.pageYOffset > banner.clientHeight && window.innerWidth > 1024){
				document.getElementById("sidenav").style.position = "fixed";
		  }else{
		  	document.getElementById("sidenav").style.position = "static";
		  }
		});
	}
});
